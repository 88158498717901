<template>
  <div class="">
    <v-app-bar :dark="$vuetify.theme.dark" flat app height="64">
      <span class="app-bar--container">
        <v-app-bar-title class="mr-5">
          <v-img
            style="cursor: pointer"
            @click="$router.push({ name: 'Home' })"
            contain
            max-width="111"
            :style="
              $vuetify.theme.dark ? '' : 'filter: brightness(1) invert(1);'
            "
            :src="require('@/assets/logos/woozLabs_white.svg')"
          >
          </v-img>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            class="font-weight-bold body-2 onSurface--text px-4 ma-4"
            text
            rounded
            :href="'https://woozlabs.notion.site/Kim-Kwan-Woo-0d4f6371566b4f92b15f9c18cb58b4da'"
            target="_blank"
          >
            {{ $t("system.pageAppbar.resume") }}
          </v-btn>

          <service-menu>
            <template v-slot:serviceMenuTrigger="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="font-weight-bold body-2 onSurface--text px-4 ma-4"
                text
                rounded
              >
                {{ $t("system.pageAppbar.techservice") }}
              </v-btn>
            </template>
          </service-menu>

          <v-btn
            class="font-weight-bold body-2 onSurface--text px-4 ma-4"
            text
            rounded
            @click="$router.push({ name: 'Archive' })"
          >
            {{ $t("system.pageAppbar.archive") }}
          </v-btn>
        </div>
        <v-spacer></v-spacer>

        <!-- DARK -->
        <v-btn
          class="mx-2"
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="toggleTheme()"
          color="secondary"
          icon
        >
          <feather color="secondary" v-if="$vuetify.theme.dark" type="sun" />
          <feather color="secondary" v-else type="moon" />
        </v-btn>

        <!-- LANGUAGE -->
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="toggleLanguage()"
          color="secondary"
          icon
        >
          <v-img
            v-if="$i18n.locale == 'en'"
            contain
            height="28"
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/icons/korea.svg')"
          >
          </v-img>
          <feather v-else type="globe" />
        </v-btn>

        <!-- LOGIN ICON -->
        <v-btn
          v-if="
            !$store.getters['auth/user/GET_USER'] && $vuetify.breakpoint.mdAndUp
          "
          class="mx-2"
          color="onSurface"
          @click="$router.push({ name: 'Login' })"
          icon
          :ripple="false"
        >
          <feather type="user" />
        </v-btn>

        <more-menu
          :top="false"
          :origin="'right top'"
          v-if="
            $vuetify.breakpoint.mdAndUp && $store.getters['auth/user/GET_USER']
          "
        >
          <template v-slot:moreMenuTrigger="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              width="40"
              height="40"
              class="background mx-2 ml-3"
              icon
              depressed
              :ripple="false"
            >
              <v-avatar size="36" color="surface">
                <v-img :src="$store.getters['auth/user/GET_USER'].photoURL" />
              </v-avatar>
            </v-btn>
          </template>
        </more-menu>

        <!-- HAMBERGER MENU -->
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          class=""
          :ripple="false"
          @click="toggleDrawer"
          icon
          color="secondary"
        >
          <v-img
            height="24"
            width="24"
            contain
            :style="
              $vuetify.theme.dark ? 'filter: brightness(0) invert(1);' : ''
            "
            :src="require('@/assets/icons/hamburger.svg')"
          >
          </v-img>
        </v-btn>
      </span>
    </v-app-bar>

    <navigation-drawer :drawer="drawer" @toggleDrawer="toggleDrawer" />
  </div>
</template>

<script>
import NavigationDrawer from "@/components/Page/NavigationBar/NavigationDrawer.vue";
import MoreMenu from "@/components/Page/Menu/MoreMenu.vue";
import ServiceMenu from "@/components/Page/Menu/ServiceMenu.vue";
export default {
  components: {
    NavigationDrawer,
    MoreMenu,
    ServiceMenu,
  },
  computed: {
    language: {
      get() {
        return this.$store.getters["page/settings/CURRENT_LANGUAGE"];
      },
      set(val) {
        this.$store.dispatch("page/settings/SET_LANGUAGE", val);
        this.$i18n.locale = val;
      },
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleTheme() {
      if (this.$vuetify.theme.dark) {
        this.$analyticsLog(this.$analytics, "theme_toggle", {
          from: "dark",
          to: "light",
        });
      } else {
        this.$analyticsLog(this.$analytics, "theme_toggle", {
          from: "light",
          to: "dark",
        });
      }
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    toggleLanguage() {
      if (this.$i18n.locale === "en") {
        this.$analyticsLog(this.$analytics, "language_toggle", {
          from: "en",
          to: "ko",
        });
        this.language = "ko";
      } else {
        this.$analyticsLog(this.$analytics, "language_toggle", {
          from: "ko",
          to: "en",
        });
        this.language = "en";
      }
    },
  },
  data() {
    return {
      scrolled: false,
      drawer: false,
      items: [
        {
          title: "🤖 Resume",
          to: "https://woozlabs.notion.site/Kim-Kwan-Woo-0d4f6371566b4f92b15f9c18cb58b4da",
        },
        {
          active: false,
          items: [
            { title: "📈 Trading AI (WIP)", to: "Trading" },
            { title: "..." },
          ],
          title: "💡 Tech & Service",
        },
        {
          title: "🖥 Records",
          to: "Records",
        },
      ],
    };
  },
};
</script>

<style></style>
