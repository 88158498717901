<template>
  <v-menu offset-y left transition="scale-transition" origin="center top">
    <template v-slot:activator="{ on, attrs }">
      <slot v-bind="{ on, attrs }" name="serviceMenuTrigger"> </slot>
    </template>

    <v-list dense outlined nav class="rounded-lg">
      <v-list-item-group>
        <v-list-item @click="$router.push({ name: 'Trading' })">
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold onSurface--text"
              v-text="$t('system.pageAppbar.child.tradingAI')"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              class="font-weight-bold onSurface--text"
              v-text="$t('system.pageAppbar.child.empty')"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      menu: false,

      selectedItem: 1,
      items: [
        { text: "설정 및 개인정보", icon: "mdi-cog", to: "MyPage" },
        { text: "도움말 및 지원", icon: "mdi-help-circle", to: "" },
        {
          text: "디스플레이 및 접근성 ",
          icon: "mdi-moon-waning-crescent",
          to: "",
        },
        { text: "로그아웃 ", icon: "mdi-logout", to: "Logout" },
      ],
    };
  },
};
</script>

<style></style>
