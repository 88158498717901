<template>
  <v-card flat color="transparent" class="mx-auto">
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card-actions class="justify-center pa-0">
        <v-btn @click="signInWithGoogle" depressed icon>
          <v-avatar size="32" color="surface">
            <v-img :src="require('@/assets/logos/auth/google.png')" />
          </v-avatar>
        </v-btn>
        <!-- signInWithKakao -->
        <v-btn icon depressed>
          <v-avatar size="32" color="#fee500">
            <v-img :src="require('@/assets/logos/auth/kakao.png')" />
          </v-avatar>
        </v-btn>
        <!-- signInWithNaver -->
        <v-btn icon depressed>
          <v-avatar size="32" color="#00c93b">
            <v-img :src="require('@/assets/logos/auth/naver.png')" />
          </v-avatar>
        </v-btn>
      </v-card-actions>

      <v-card-actions class="pa-0">
        <v-divider></v-divider>
        <v-subheader>or</v-subheader>
        <v-divider></v-divider>
      </v-card-actions>

      <v-card-text class="pa-0" v-if="!emailSignUp">
        <v-btn
          class="rounded-lg body-2 font-weight-bold"
          block
          large
          depressed
          @click="emailSignUp = !emailSignUp"
          color="primary"
        >
          {{ this.$t("onBoarding.authorization.signUp.email") }}
        </v-btn>
      </v-card-text>
      <v-card-text v-else class="pa-2">
        <div class="font-weight-bold my-2 mt-4">
          {{ this.$t("onBoarding.authorization.signUp.emailLabel") }}
        </div>
        <v-text-field
          label="user@example.com"
          v-model="form.email"
          :rules="[rule.required, rule.maxLength(50), rule.email]"
          required
          hide-details
          single-line
          flat
          dense
          outlined
        ></v-text-field>
        <div class="font-weight-bold my-2 mt-4">
          {{ this.$t("onBoarding.authorization.signUp.nameLabel") }}
        </div>
        <v-text-field
          :label="this.$t('onBoarding.authorization.signUp.nameLabel')"
          v-model="form.name"
          :rules="[rule.required, rule.minLength(1), rule.maxLength(10)]"
          required
          hide-details
          single-line
          flat
          dense
          outlined
        ></v-text-field>
        <!-- <v-text-field
                    label="사용자 이름"
                    v-model="form.userName"
                    :rules="[rule.maxLength(20)]"
                ></v-text-field> -->
        <div class="font-weight-bold my-2 mt-4">
          {{ this.$t("onBoarding.authorization.signUp.pwLabel") }}
        </div>
        <v-text-field
          :label="this.$t('onBoarding.authorization.signUp.pwLabel')"
          style="ime-mode: disabled"
          v-model="form.password"
          :rules="[rule.required, rule.minLength(8), rule.maxLength(50)]"
          type="password"
          required
          hide-details
          single-line
          flat
          dense
          outlined
        ></v-text-field>

        <v-btn
          color="primary"
          large
          :disabled="!valid || form.name == ''"
          depressed
          :loading="loading"
          class="font-weight-bold body-2 mt-5 rounded-lg"
          block
          @click="createWithEmailAndPassword"
        >
          {{ this.$t("onBoarding.authorization.signUp.signIn") }}
        </v-btn>
      </v-card-text>

      <div class="recaptcha-terms-text caption text-center mt-4">
        {{ this.$t("onBoarding.authorization.signUp.notice") }}
      </div>
      <v-card-text
        class="text-center pa-0 mt-4 onSurface--text font-weight-regular"
      >
        {{ this.$t("onBoarding.authorization.signUp.haveAccount") }}
        <a class="subtitle-2 primary--text" @click="$emit('changeType', 1)">
          {{ this.$t("onBoarding.authorization.signUp.signIn") }}
        </a>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<style scoped>
.recaptcha-terms-text {
  font-size: 12px;
  font-weight: 200;
  color: #637282;
}
</style>

<script>
export default {
  created() {
    this.valid = false;
  },
  data() {
    return {
      loading: false,
      emailSignUp: false,
      valid: false,
      form: {
        name: "",
        // userName: '',
        email: "",
        password: "",
      },
      rule: {
        required: (v) => !!v || "필수 항목입니다.",
        minLength: (length) => (v) =>
          v.length >= length || `${v.length} / ${length}`,
        maxLength: (length) => (v) =>
          v.length <= length || `${v.length} / ${length}`,
        email: (v) => /.+@.+/.test(v) || "이메일 형식에 맞지 않습니다.",
      },
    };
  },
  methods: {
    async signInWithGoogle() {
      const provider = new this.$firebase.auth.GoogleAuthProvider();
      this.$firebase.auth().languageCode = "ko";
      try {
        await this.$firebase.auth().signInWithPopup(provider);
        const user = this.$firebase.auth().currentUser;
        await user.getIdToken();
        await this.$store.dispatch("auth/user/GET_USER", user);
        if (
          this.$store.getters["auth/user/GET_CLAIMS"] === null ||
          this.$store.getters["auth/user/GET_CLAIMS"].level === undefined ||
          this.$store.getters["auth/user/GET_CLAIMS"].level > 1
        ) {
          this.$router
            .push({ name: "MyPage" })
            .then(() => window.scrollTo(0, 0));
        }
        this.$router.push("/page/");
      } catch (e) {
        switch (e.code) {
          case "auth/invalid-email":
            this.$toasted.global.error("이메일을 다시 확인해주세요.");
            return;
          case "auth/email-already-in-use":
            this.$toasted.global.error("이미 사용중인 이메일입니다.");
            return;
          default:
            this.$toasted.global.error("오류가 발생했습니다.");
            return;
        }
      } finally {
        this.loading = false;
      }
    },
    async createWithEmailAndPassword() {
      if (!this.$refs.form.validate())
        return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");
      this.loading = true;
      try {
        await this.$firebase
          .auth()
          .createUserWithEmailAndPassword(this.form.email, this.form.password);
        this.$toasted.global.notice(
          "가입이 완료되었습니다. 이메일을 확인해주세요",
        );
        const user = this.$firebase.auth().currentUser;
        await user.updateProfile({
          displayName: this.form.name,
        });
        this.$firebase.auth().languageCode = "ko";
        await user.sendEmailVerification();
        await this.$firebase.auth().signOut();
        this.$emit("changeType", 1);
      } catch (e) {
        switch (e.code) {
          case "auth/invalid-email":
            this.$toasted.global.error("이메일을 다시 확인해주세요.");
            return;
          case "auth/email-already-in-use":
            this.$toasted.global.error("이미 사용중인 이메일입니다.");
            return;
          default:
            this.$toasted.global.error("오류가 발생했습니다.");
            return;
        }
      } finally {
        this.loading = false;
      }
    },

    signInWithKakao() {
      const protocol = location.protocol;
      const hostName = location.hostname;
      const port = location.port;

      let url = protocol + "//" + hostName + (port ? ":" + port : "");
      url += "/callback/kakaotalk";

      window.Kakao.Auth.authorize({
        redirectUri: url,
        //        , state:""
        //        , scope:""
        throughTalk: true,
      });
    },
    signInWithNaver() {
      const state =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      // console.log(state);
      window.localStorage.setItem("naverState", state);

      const protocol = location.protocol;
      const hostName = location.hostname;
      const port = location.port;

      let url = protocol + "//" + hostName + (port ? ":" + port : "");
      url += "/callback/naver";

      const authUrl = "https://nid.naver.com/oauth2.0/authorize";
      const params = [];
      params.push("response_type=code");
      params.push("client_id=" + process.env.VUE_APP_NAVER_APP_CLIENT_ID);
      params.push("redirect_uri=" + url);
      params.push("state=" + state);

      const authCodeUrl = authUrl + "?" + params.join("&");
      location.href = authCodeUrl;
    },
  },
};
</script>
