<template>
  <v-card flat color="transparent" class="mx-auto">
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card-text class="pa-2">
        <div class="font-weight-bold my-2 mt-4">
          {{ this.$t("onBoarding.authorization.signUp.emailLabel") }}
        </div>
        <v-text-field
          label="user@example.com"
          v-model="form.email"
          :rules="[rule.required, rule.maxLength(50), rule.email]"
          required
          hide-details
          flat
          single-line
          dense
          outlined
        ></v-text-field>
        <v-btn
          color="primary"
          large
          :disabled="!valid || form.email == ''"
          depressed
          class="font-weight-bold body-2 mt-5 rounded-lg"
          block
          @click="resetPasswordwithEmail"
        >
          {{ this.$t("onBoarding.authorization.resetPassword.sendEmail") }}
        </v-btn>
      </v-card-text>
      <v-card-text
        class="text-center pa-0 mt-4 onSurface--text font-weight-regular"
      >
        {{ this.$t("onBoarding.authorization.signUp.haveAccount") }}
        <a class="subtitle-2 primary--text" @click="$emit('changeType', 1)">
          {{ this.$t("onBoarding.authorization.signUp.signIn") }}
        </a>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<style scoped>
.recaptcha-terms-text {
  font-size: 12px;
  font-weight: 200;
  color: #637282;
}
</style>

<script>
export default {
  created() {
    this.valid = false;
  },
  data() {
    return {
      emailSignUp: false,
      valid: false,
      form: {
        name: "",
        // userName: '',
        email: "",
        password: "",
      },
      rule: {
        required: (v) => !!v || "필수 항목입니다.",
        minLength: (length) => (v) =>
          v.length >= length || `${v.length} / ${length}`,
        maxLength: (length) => (v) =>
          v.length <= length || `${v.length} / ${length}`,
        email: (v) => /.+@.+/.test(v) || "이메일 형식에 맞지 않습니다.",
      },
    };
  },
  methods: {
    async resetPasswordwithEmail() {
      if (!this.$refs.form.validate())
        return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");
      try {
        await this.$firebase.auth().sendPasswordResetEmail(this.form.email);
        this.$toasted.global.notice("이메일을 확인해주세요");
      } catch (e) {
        switch (e.code) {
          case "auth/invalid-email":
            this.$toasted.global.error("이메일을 다시 확인해주세요.");
            return;
          case "auth/email-already-in-use":
            this.$toasted.global.error("이미 사용중인 이메일입니다.");
            return;
          default:
            this.$toasted.global.error("오류가 발생했습니다.");
            return;
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
