<template>
  <v-card flat color="transparent" class="mx-auto mt-2">
    <v-form v-model="valid" ref="form" lazy-validation>
      <v-card-actions class="justify-center pa-0">
        <v-btn @click="signInWithGoogle" depressed icon>
          <v-avatar size="32" color="surface">
            <v-img :src="require('@/assets/logos/auth/google.png')" />
          </v-avatar>
        </v-btn>
        <!-- signInWithKakao -->
        <v-btn icon depressed>
          <v-avatar size="32" color="#fee500">
            <v-img :src="require('@/assets/logos/auth/kakao.png')" />
          </v-avatar>
        </v-btn>
        <!-- signInWithNaver -->
        <v-btn icon depressed>
          <v-avatar size="32" color="#00c93b">
            <v-img :src="require('@/assets/logos/auth/naver.png')" />
          </v-avatar>
        </v-btn>
      </v-card-actions>

      <v-card-actions class="pa-0">
        <v-divider></v-divider>
        <v-subheader>or</v-subheader>
        <v-divider></v-divider>
      </v-card-actions>

      <v-card-text class="pa-0">
        <div class="font-weight-bold my-2">
          {{ this.$t("onBoarding.authorization.signIn.emailLabel") }}
        </div>
        <v-text-field
          label="user@example.com"
          v-model="form.email"
          :rules="[rule.required, rule.email]"
          required
          hide-details
          flat
          single-line
          dense
          outlined
        ></v-text-field>
        <div class="font-weight-bold my-2 mt-4">
          {{ this.$t("onBoarding.authorization.signIn.pwLabel") }}
        </div>
        <v-text-field
          :label="this.$t('onBoarding.authorization.signIn.pwLabel')"
          style="ime-mode: disabled"
          v-model="form.password"
          :rules="[rule.required]"
          type="password"
          required
          hide-details
          flat
          single-line
          dense
          outlined
        ></v-text-field>

        <div class="text-center">
          <v-btn
            color="primary"
            :disabled="!valid"
            depressed
            large
            :loading="loading"
            block
            class="font-weight-bold body-2 mt-5 rounded-lg"
            @click="signInWithEmail"
          >
            {{ this.$t("onBoarding.authorization.signIn.login") }}
          </v-btn>
        </div>
      </v-card-text>

      <v-card-text
        class="text-center pa-0 mt-4 onSurface--text font-weight-regular"
      >
        {{ this.$t("onBoarding.authorization.signIn.new") }}
        <a class="subtitle-2 primary--text" @click="$emit('changeType', 2)">
          {{ this.$t("onBoarding.authorization.signIn.signUp") }}</a
        >

        <div class="text-center mt-2 font-weight-regular">
          <a class="subtitle-2 onSurface--text" @click="$emit('changeType', 3)">
            {{ this.$t("onBoarding.authorization.signIn.findPw") }}</a
          >
        </div>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        Name: "",
        // userName: '',
        email: "",
        password: "",
      },
      rule: {
        required: (v) => !!v || "필수 항목입니다.",
        email: (v) => /.+@.+/.test(v) || "이메일 형식에 맞지 않습니다.",
      },
    };
  },
  methods: {
    async signInWithGoogle() {
      const provider = new this.$firebase.auth.GoogleAuthProvider();
      this.$firebase.auth().languageCode = "ko";
      try {
        await this.$firebase.auth().signInWithPopup(provider);
        const user = this.$firebase.auth().currentUser;
        await user.getIdToken();
        await this.$store.dispatch("auth/user/GET_USER", user);

        if (
          this.$store.getters["auth/user/GET_CLAIMS"] === null ||
          this.$store.getters["auth/user/GET_CLAIMS"].level === undefined ||
          this.$store.getters["auth/user/GET_CLAIMS"].level > 1
        ) {
          this.$router
            .push({ name: "MyPage" })
            .then(() => window.scrollTo(0, 0));
        }
        this.$router.push({ name: "Home" }).then(() => window.scrollTo(0, 0));
      } catch (e) {
        switch (e.code) {
          case "auth/invalid-email":
            this.$toasted.global.error("이메일을 다시 확인해주세요.");
            return;
          case "auth/email-already-in-use":
            this.$toasted.global.error("이미 사용중인 이메일입니다.");
            return;
          default:
            this.$toasted.global.error("오류가 발생했습니다.");
            return;
        }
      } finally {
        this.loading = false;
      }
    },
    async signInWithEmail() {
      if (!this.$refs.form.validate())
        return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");

      this.loading = true;
      try {
        await this.$firebase
          .auth()
          .signInWithEmailAndPassword(this.form.email, this.form.password);
      } catch (e) {
        this.loading = false;
        switch (e.code) {
          case "auth/wrong-password":
            return this.$toasted.global.error(
              "이메일 혹은 비밀번호를 확인해주세요",
            );

          default:
            return this.$toasted.global.error(
              "이메일 혹은 비밀번호를 확인해주세요",
            );
        }
      }
      const user = this.$firebase.auth().currentUser;
      await user.getIdToken();
      await this.$store.dispatch("auth/user/GET_USER", user);

      // User Verifiy
      if (this.$store.getters["auth/user/GET_USER"].emailVerified === false) {
        this.$toasted.global.error("이메일을 확인해주세요.");
        return await this.$firebase.auth().signOut();
      } else {
        // User Custom Claims Problem
        var provideUser = this.$store.getters["auth/user/GET_USER"];
        var providerData = {
          displayName: provideUser.displayName,
          email: provideUser.email,
          emailVerified: provideUser.emailVerified,
          photoURL: provideUser.photoURL,
        };

        await this.$axios.patch(
          "users/updateData/" + this.$store.getters["auth/user/GET_USER"].uid,
          {
            userData: providerData,
          },
        );

        this.loading = false;
        if (this.$store.getters["auth/user/GET_CLAIMS"].level === undefined)
          this.$router
            .push({ name: "MyPage" })
            .then(() => window.scrollTo(0, 0));
        this.$router.push("/");
      }
    },
    signInWithKakao() {
      const protocol = location.protocol;
      const hostName = location.hostname;
      const port = location.port;

      let url = protocol + "//" + hostName + (port ? ":" + port : "");
      url += "/callback/kakaotalk";

      window.Kakao.Auth.authorize({
        redirectUri: url,
        throughTalk: true,
      });
    },
    signInWithNaver() {
      const state =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      // console.log(state);
      window.localStorage.setItem("naverState", state);

      const protocol = location.protocol;
      const hostName = location.hostname;
      const port = location.port;

      let url = protocol + "//" + hostName + (port ? ":" + port : "");
      url += "/callback/naver";

      const authUrl = "https://nid.naver.com/oauth2.0/authorize";
      const params = [];
      params.push("response_type=code");
      params.push("client_id=" + process.env.VUE_APP_NAVER_APP_CLIENT_ID);
      params.push("redirect_uri=" + url);
      params.push("state=" + state);

      const authCodeUrl = authUrl + "?" + params.join("&");
      location.href = authCodeUrl;
    },
  },
};
</script>

<style scoped>
.recaptcha-terms-text {
  font-size: 12px;
  font-weight: 200;
  color: #637282;
}
</style>
