var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0 tranparent",attrs:{"fill-height":""}},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-auto ma-auto",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-8 mx-auto",attrs:{"max-width":"400","rounded":"xl","color":_vm.$vuetify.breakpoint.smAndDown ? 'transparent' : 'transparent',"flat":""}},[_c('v-card',{staticClass:"transparent",attrs:{"flat":""}},[_c('div',{staticClass:"pa-2",class:_vm.$vuetify.breakpoint.smAndDown || !_vm.type
                ? 'text-center'
                : 'text-center'},[_c('span',{staticClass:"font-weight-bold headline",domProps:{"innerHTML":_vm._s(
                _vm.type == 1
                  ? this.$t('onBoarding.authorization.signIn.title')
                  : _vm.type == 2
                  ? this.$t('onBoarding.authorization.signUp.title')
                  : this.$t('onBoarding.authorization.resetPassword.title')
              )}})])]),(_vm.type == 1)?_c('sign-in',{on:{"changeType":_vm.changeType}}):(_vm.type == 2)?_c('sign-up',{on:{"changeType":_vm.changeType}}):_c('reset-password',{on:{"changeType":_vm.changeType}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }