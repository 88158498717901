<template>
  <v-container fill-height class="pa-0 tranparent">
    <v-row class="ma-0">
      <v-col cols="12" class="pa-auto ma-auto">
        <v-card
          max-width="400"
          class="pa-8 mx-auto"
          rounded="xl"
          :color="$vuetify.breakpoint.smAndDown ? 'transparent' : 'transparent'"
          flat
        >
          <v-card flat class="transparent">
            <div
              :class="
                $vuetify.breakpoint.smAndDown || !type
                  ? 'text-center'
                  : 'text-center'
              "
              class="pa-2"
            >
              <span
                class="font-weight-bold headline"
                v-html="
                  type == 1
                    ? this.$t('onBoarding.authorization.signIn.title')
                    : type == 2
                    ? this.$t('onBoarding.authorization.signUp.title')
                    : this.$t('onBoarding.authorization.resetPassword.title')
                "
              >
              </span>
            </div>
          </v-card>
          <sign-in v-if="type == 1" @changeType="changeType"></sign-in>
          <sign-up v-else-if="type == 2" @changeType="changeType"></sign-up>
          <reset-password v-else @changeType="changeType" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style></style>
<script>
import SignIn from "@/components/Authorization/signIn";
import SignUp from "@/components/Authorization/signUp";
import setMeta from "@/utils/setMeta";
import PageNavigationBar from "../../components/Page/NavigationBar/PageNavigationBar.vue";
import ResetPassword from "@/components/Authorization/resetPassword.vue";

export default {
  components: {
    SignIn,
    SignUp,
    ResetPassword,
  },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    changeType(index) {
      this.type = index;
    },
  },
  mounted() {
    setMeta({ title: "로그인 | Wooz Labs", description: "Wooz Labs 로그인" });
  },
  created() {},
};
</script>
